import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Section, Container, Banner } from 'components';

export const query = graphql`
  query AppliancesPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    bannerImage: file(relativePath: { eq: "assets/images/appliances-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    appliances: allSanityAppliances(sort: { fields: title }) {
      edges {
        node {
          title
          _rawBody
          id
          logo {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          banner {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          link
        }
      }
    }
  }
`;

const AppliancesPage = (props) => {
  const { data } = props;
  const site = (data || {}).site;
  const { bannerImage } = data;
  const { edges: appliances } = data.appliances;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  const mainBanner = {
    caption: 'Our Appliances',
    text: 'Check out a few of our recommended appliance suppliers below.',
    img: bannerImage
  };
  console.log(appliances);

  return (
    <main>
      <SEO
        title="Appliances - Our Suppliers"
        description="Check out a few of our recommended appliance suppliers. We use only top quality brands to supply our kitchens."
        keywords={site.keywords}
      />
      <Banner banner={mainBanner} />
      <Container>
        {appliances.map(({ node }, i) => (
          <Section
            key={node.id}
            logo={node.logo}
            img={node.banner}
            title={node.title}
            portableText={node._rawBody}
            flipped={i % 2 == 0}
            link={node.link}
          />
        ))}
      </Container>
    </main>
  );
};

export default AppliancesPage;
